import React from "react"

import PageTitle from "../components/page-title"
import SEO from "../components/seo"

const AboutIndex = () => (
    <>
        <SEO
            title="About"
            description=""
        />

        <PageTitle summary="With over 25 years experience in the Networking and Data Field we have mastered most things
        imaginable. PKD has taken dilapidated failing out of date networks and given customers a new lease of life bringing
        them into the 21st century. At PKD we always listen as this is the first step to making a project a reality."
        title="A bit about us"/>

        <div className={"mb-10 lg:mb-32 px-6 md:px-12 mx-auto prose prose-lg"}>
            <p>The PKD Tech Technical Network Engineering services are delivered nationally by our dedicated Team of certified engineers in many platforms and certifications allowing you to have the best service possible.
              Our priority is always to address your needs and resolve your network problems.</p>
            <p>Don’t put your network – and your business – at risk by choosing the wrong support provider. Contact PKD Tech on +44 (0)1983 563 763 today to find out more about our Technical Network Engineering service.</p>
        </div>
    </>
)

export default AboutIndex
